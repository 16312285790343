import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import { ArrowButton } from 'components/_universal/ArrowButton';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import React from 'react';
import { ROUTE_CAREER } from 'shared/paths';
import { B1 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export default () => {
    const isMobile = useBreakpoint('sm');
    return (
        <CommonMargin>
            <Section
                title="Join us! Create a great future with us!"
                isFirst
                withBreadCrumbs
            >
                <B1 mb={8} mt={8}>
                    We are a strong team of individuals who are experts in
                    delivering digital projects and passionate about the methods
                    we use to get there.
                </B1>
                <Common.Div flex={isMobile ? 'column' : 'row'} gap="32px">
                    <ArrowButton
                        onClick={() => navigate(ROUTE_CAREER)}
                        text={'see our open positions'}
                    />
                    <ArrowButton
                        onClick={() => {
                            if (typeof window !== 'undefined') {
                                window.location.href =
                                    'https://codahead.elevato.net/en/general,ja,60?source=2042';
                            }
                        }}
                        text={'leave your cv'}
                    />
                </Common.Div>
            </Section>
        </CommonMargin>
    );
};
